import $ from "jquery";

/**
 * @author Lefthandmedia <ralph@lefthandmedia.com>
 */


let isSmall = false;

$(document).on("scroll", function () {
  
  console.log(isSmall);
  if ($(document).scrollTop() > 60 && !isSmall) {
    setTimeout(function () {
      $(".menu-container").addClass("small");
      isSmall = true;
    }, 50)
  } else if($(document).scrollTop() < 20 && isSmall){
    setTimeout(function () {
      $(".menu-container").removeClass("small");
      isSmall = false;
    }, 50)
  }
});
