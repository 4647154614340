import $ from 'jquery';
import whatInput from 'what-input';
import './components/menuscroll';

window.$ = $;
$(document).foundation();

// import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below

import './lib/foundation-explicit-pieces';
import {BreakMonitor} from "./classes/BreakMonitor";

// Workaround to get the offcanvas toggle to work on IOS
$('[data-toggle]').on('click', function() {
  $(document).trigger('click.zf.trigger', '[data-toggle]');
});

 //let breakmonitor = new BreakMonitor();