/**
 * @author Lefthandmedia <ralph@lefthandmedia.com>
 *
 *      Adds a div to the top of the page to show in which breakpoit we are.
 *      depends on: Jquery
 *      This class is ONLY used during DEVELOPMENT and sets a small element to show in which breakpoint the viewport sits.
 *
 *      let breakmonitor = new Breakmonitor();
 *      breakmonitor.addmonitor();
 *
 */
import $ from 'jquery';

class BreakMonitor {

  constructor() {
    this.addmonitor();
    window.addEventListener('resize',this.resizehandler);
    this.resizehandler();
  }

  addmonitor() {
    let $css = "<style> #breakmonitor{position:fixed;top:-7px;height:auto;width:auto;font-family:Consolas,\"Liberation Mono\",Courier,monospace;z-index:99999} " +
        " #debug-size{font-size:12px;user-select: none;}" +
        ".tell{background-color:#d12157;color:#fff;cursor:pointer;height:auto;padding:.25rem 1rem;width:auto;z-index:1000;overflow:hidden;transition:all .25s ease-out;display:inline-block}" +
        ".tell.short{width:8px;height:8px;padding:0 4px}" +
        ".tell.short:after{height:auto;opacity:0}" +
        ".tell:after{content:'small';position:relative}" +
      "@media print,screen and (min-width:30em){.tell{color:#000;background-color:chartreuse}.tell:after{content:'phone'}}" +
      "@media print,screen and (min-width:40em){.tell{background-color:orange}.tell:after{content:'medium'}}" +
      "@media print,screen and (min-width:64em){.tell{color:#fff;background-color:purple}.tell:after{content:'large'}}" +
      "@media print,screen and (min-width:75em){.tell{color:#fff;background-color:navy}.tell:after{content:'xlarge'}}" +
      "@media print,screen and (min-width:90em){.tell{color:#fff;background-color:plum}.tell:after{content:'xxlarge'}}</style>";
    $('body').prepend($css);
    $('body').prepend('<div id="breakmonitor"><span id="debug-tell" class="tell short"></span><span id="debug-size"></span></div>');
    $("#breakmonitor").click(function () {
      $("#debug-tell").toggleClass('short')
    });
  }
  
  resizehandler(){
    console.log(window.innerWidth);
    $('#debug-size').html(window.innerWidth +"x"+window.innerHeight);
  }
}

export {BreakMonitor};
